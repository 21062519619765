import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory, useLocation } from "react-router";
import {
  Container,
  TextField,
  InputAdornment,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { Button, makeStyles, IconButton } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import OrderDetails from "./OrderDetails";
import Payment from "./payment";
import Activities from "./activities";
import Mockup from "./Mockup";
import Layout from "../../components/Layout";
import Notes from "./customerNotes";
import PrintNotes from "./printNotes";
import {
  getSingleOrderDetails,
  getOtherOrders,
  makeorderpaid,
  getorderpaid,
} from "../../api";
import GoBackButton from "../../components/GoBackButton";
import OrderStatus from "../../components/utils/OrderStatus";
import OrderStatusForPrintJobs from "../../components/utils/OrderStatusForPrintJobs";
import SearchIcon from "@mui/icons-material/Search";
import LinearProgress from "@material-ui/core/LinearProgress";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import PinPopup from "../order/PinPop";
import PinPopupMessage from "../order/PinPopupMessage";
import PushPin from "@mui/icons-material/PushPin";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import TrackEvents from "../../api/amplitudeEvents";
const PinComponent = ({
  order,
  id,
  res,
  setRes,
  setChangeImportantorderstatus,
  changeImportantorderstatus,
}) => {
  const classes = useStyles();
  const [openPinpop, setPinPop] = useState(false);

  const handleClosePinPop = () => {
    setPinPop(false);
  };
  console.log();
  return (
    <>
      <Box
        width="auto"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        {order.pinnedorder == "yes" ? (
          <Typography
            sx={{
              backgroundColor: "red",
              color: "#fff",
              mr: "5px",
              p: "5px 10px",
            }}
          >
            Important Order
          </Typography>
        ) : (
          <Typography
            sx={{
              backgroundColor: "#3f51b5",
              color: "#fff",
              mr: "5px",
              p: "5px 10px",
              cursor: "pointer",
            }}
            onClick={() => setPinPop(true)}
          >
            {changeImportantorderstatus
              ? "Processing..."
              : "Mark Order As Important"}
          </Typography>
        )}
      </Box>
      <PinPopup
        open={openPinpop}
        handleClose={handleClosePinPop}
        id={id}
        res={res}
        setRes={setRes}
        changeImportantorderstatus={changeImportantorderstatus}
        setChangeImportantorderstatus={setChangeImportantorderstatus}
      />
    </>
  );
};

const PinComponentMessage = ({ order, id, res, setRes }) => {
  const classes = useStyles();
  const [openPinMessagepop, setPinMessagePop] = useState(false);

  const handleClosePinMessagePop = () => {
    setPinMessagePop(false);
  };

  return (
    <>
      <Box
        width="60%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          fontSize="small"
          className={classes.pinIcon}
          onClick={() => setPinMessagePop(true)}
        >
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      <PinPopupMessage
        open={openPinMessagepop}
        handleClose={handleClosePinMessagePop}
        id={id}
        res={res}
        setRes={setRes}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  orderbyheading: {
    fontSize: "18px",
    lineHeight: 1.3,
  },
  addOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "8px",
    },
  },
  printOrderbtn: {
    padding: "4px 8px",
    fontSize: "13px",
    textTransform: "none",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "8px",
    },
  },
  statusBtn: {
    boxShadow: "none",
    textTransform: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #8c8f94",
    borderRadius: "4px",
    padding: "0 8px",
    lineHeight: 2,
    height: "30px",
    marginRight: "1rem",
    backgroundColor: "#ffffff",
  },
  statusText: {
    // borderRight: '1px solid #000000',
    fontSize: "13px",
    color: "#646970",
    marginRight: "5px",
  },
  orderId: {
    color: "#2271b1",
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  bulkActions: {
    height: "32px",
    width: "12.5rem",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    lineheight: 2,
    color: "#2c3338",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      backgroundColor: "transparent",
    },
  },
  selectpaper: {
    width: "152px",
    boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
    borderRadius: "8px",
  },
  menuItem: {
    fontSize: "12px",
  },
  hold: {
    backgroundColor: "#f8dda7",
    color: "#94660c",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      ackgroundColor: "#f8dda7",
      color: "#94660c",
      boxShadow: "none",
    },
  },
  completed: {
    backgroundColor: "#c8d7e1",
    color: "#2e4453",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c8d7e1",
      color: "#2e4453",
      boxShadow: "none",
    },
  },
  processing: {
    backgroundColor: "#c6e1c6",
    color: "#5b841b",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#c6e1c6",
      color: "#5b841b",
      boxShadow: "none",
    },
  },
  cancelled: {
    color: "#777",
    backgroundColor: "#e5e5e5",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      color: "#777",
      backgroundColor: "#e5e5e5",
      boxShadow: "none",
    },
  },
  viewbtn: {
    border: "#D1D5DB solid 1px",
    textTransform: "none",
  },
  loading: {
    // height: '5px',
    color: "#c6e1c6",
  },
  editIcon: {
    height: "15px !important",
    marginLeft: "7px",
    cursor: "pointer",
  },
  showgreen: {
    backgroundColor: "#a2f8a2",
    color: "#ffffff",
  },
  orderbybox: {
    backgroundColor: "#fff",
    padding: "1rem",
    marginTop: "1rem",
    borderRadius: "4px",
  },
  orderbyButton: {
    display: "inline-block",
    padding: "9px 10px !important",
    minWidth: "100px !important",
  },
  orderTabs: {
    "& .MuiTab-root": {
      [theme.breakpoints.down("xs")]: {
        minWidth: "120px",
      },
    },
  },
  searchInputBox: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      marginTop: "8px",
    },
  },
  searchInput: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "0 !important",
      marginLeft: "0 !important",
      width: "172px !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
  },
  orderTopBar: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0 !important",
      paddingLeft: "0 !important",
    },
  },
  tabPanel: {
    "& > .MuiBox-root": {
      [theme.breakpoints.down("xs")]: {
        padding: "16px",
      },
    },
  },
  printOrderDateBox: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
  orderStatusBtn: {
    "& .MuiIconButton-root": {
      padding: "0 12px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "8px",
      },
    },
    "& .MuiButton-contained": {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "8px",
      },
    },
  },
  orderIdBox: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "8px !important",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function getTheCurrentUrl() {
  const url = window.location.pathname;
  const pagename = url.split("/")[1];
  return pagename;
}

export default function BasicTabs() {
  const classes = useStyles();
  const pagename = getTheCurrentUrl();
  const { print } = useParams();
  const history = useHistory();
  const search = useLocation().search;
  const path = useLocation().pathname;
  const tab = new URLSearchParams(search).get("tab");
  const id = new URLSearchParams(search).get("id");
  const [value, setValue] = useState(parseInt(tab) || 0);
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [changedorderstatus, setChangedOrderStatus] = useState("none");
  const [res, setRes] = useState(false);
  const [changeImportantorderstatus, setChangeImportantorderstatus] =
    useState(false);
  const [changedprintorderstatus, setChangedPrintOrderStatus] =
    useState("none");
  const [paidOrder, setPaidOrder] = useState();
  //const [row, setRow] = useState({});
  const row = order;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push({
      pathname: path,
      search: `?id=${id}&tab=${newValue}`,
    });
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      //const data = await getOrderDetails(id);
      const data = await getSingleOrderDetails(id);
      setOrder(data);
      //setRow(order);
      setLoading(false);
    };
    // if (id) {
    // fetchOrder();
    // }
    fetchOrder();
  }, [
    id,
    changedorderstatus,
    changedprintorderstatus,
    changeImportantorderstatus,
    paidOrder,
  ]);

  // Search
  const [searchOrder, setSearchOrder] = useState("");
  const [searchOrderList, setSearchOrderList] = useState([]);

  const handleSearch = (e) => {
    console.log(e.target.value.length);
    if (e.target.value.length > 0) {
      setSearchOrder(e.target.value);
    } else {
      setSearchOrderList([]);
      setSearchOrder("");
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        if (searchOrder.length > 2) {
          var data = await getOtherOrders(1, 10, searchOrder);
          setSearchOrderList(data.responseData.response);
        } else {
          setSearchOrderList([]);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchOrder();
  }, [searchOrder]);

  const handleOrderDetails = (e, id) => {
    e.stopPropagation();
    history.push({
      pathname: `/orders/1/details`,
      search: `?id=${id}&tab=0`,
    });
    setSearchOrder("");
    setSearchOrderList([]);
  };

  // order By Date

  const [printOrderByDate, setPrintOrderByDate] = useState(false);
  const prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 2);
  const today = new Date();
  const currentDate = new Date();
  const [fromDate, setFromDate] = useState(prevDate);
  const [toDate, setToDate] = useState(currentDate);
  const [fromError, setFromError] = useState();
  const [toError, setToError] = useState();

  moment(fromDate).format("l");
  moment(toDate).format("l");

  const handleOrderByConfirmed = (e) => {
    e.preventDefault();
    if (toDate <= fromDate) {
      setFromError("From date should be less than To date");
      return;
    }
    if (toDate > today) {
      setToError("To date shouldn't be bigger than current date");
      return;
    }
    const formattedfromdate = moment(fromDate).format("YYYY-MM-DD");
    const formattedtodate = moment(toDate).format("YYYY-MM-DD");
    const win = window.open(
      `https://printyocrm.printyo.net.au/order/printdate/bydate?from=${formattedfromdate}&to=${formattedtodate}`,
      "_blank"
    );
    // win.focus();
    setFromError(!fromError);
    setToError(!toError);
  };

  const handleSelectedOrders = (e) => {
    const win = window.open(
      `https://printyocrm.printyo.net.au/order/printdate/byids?ids=${id}`,
      "_blank"
    );
    win.focus();
  };

  const getPaidOrder = async () => {
    const getpaidorder = await getorderpaid(id);
    if (getpaidorder.markorder) {
      setPaidOrder(getpaidorder.markorder.status);
    }
    console.log(getpaidorder.markorder);
    console.log(paidOrder);
  };
  useEffect(() => {
    getPaidOrder();
  }, []);

  const MarkOrderPaid = ({ row }) => {
    if (row.payment_status == "paid") {
      return (
        <Button
          variant="contained"
          size="small"
          className={`order-status-button orderpaid`}
        >
          Paid
        </Button>
      );
    } else {
      if (row.payment_status == "unpaid") {
        return (
          <Button
            variant="contained"
            size="small"
            className={`order-status-button markpaid`}
          >
            UnPaid
          </Button>
        );
      } else {
        if (paidOrder) {
          return (
            <Button
              variant="contained"
              size="small"
              className={`order-status-button orderpaid`}
            >
              {paidOrder}
            </Button>
          );
        } else if (
          row.status == "pending-payment" ||
          row.status == "cancelled"
        ) {
          return (
            <Button
              variant="contained"
              size="small"
              className={`order-status-button markpaid`}
              onClick={() => {
                markOrderAsPaidOrder(row.id, row.status);
              }}
            >
              UnPaid (Mark as Paid)
            </Button>
          );
        } else if (
          row.payment_method == "bacs" ||
          row.payment_method == "cheque"
        ) {
          return (
            <Button
              variant="contained"
              size="small"
              className={`order-status-button markpaid`}
              onClick={() => {
                markOrderAsPaidOrder(row.id, row.status);
              }}
            >
              UnPaid (Mark as Paid)
            </Button>
          );
        } else {
          return (
            <Button
              variant="contained"
              size="small"
              className={`order-status-button orderpaid`}
            >
              Paid
            </Button>
          );
        }
      }
    }
  };

  const markOrderAsPaidOrder = async (id, status) => {
    setLoading(true);
    const userid = localStorage.getItem("auth_id");
    var data = new FormData();
    data.append("orderstatus", "processing");
    data.append("userid", userid);
    const paidorder = await makeorderpaid(id, data);
    setLoading(false);
    setPaidOrder("paid");
    TrackEvents(`${id}`, {
      process: "Order Marked As Paid In Pending Order",
    });
  };

  return (
    <Layout>
      <Box
        sx={{ width: "100%", backgroundColor: "#f0f0f1" }}
        minHeight={`calc(100vh - 63px)`}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "#fff",
          }}
        >
          <Tabs
            className={classes.orderTabs}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab label="Order" {...a11yProps(0)} sx={{ minWidth: "180px" }} />
            <Tab
              label="Activities"
              {...a11yProps(1)}
              sx={{ minWidth: "180px" }}
            />
            <Tab label="Mockup" {...a11yProps(2)} sx={{ minWidth: "180px" }} />
            <Tab label="Payment" {...a11yProps(3)} sx={{ minWidth: "180px" }} />
            <Tab
              label="Print Notes"
              {...a11yProps(4)}
              sx={{ minWidth: "180px" }}
            />
            <Tab
              label="Print Files"
              {...a11yProps(5)}
              sx={{ minWidth: "180px" }}
            />
            {pagename === "print" && (
              <Tab
                label="Printap"
                {...a11yProps(6)}
                sx={{ minWidth: "180px" }}
              />
            )}
          </Tabs>
        </Box>
        <Container>
          <Stack
            display="flex"
            direction="row"
            justifyContent="start"
            pl={3}
            pr={2}
            pt={3}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between", // Align start and end
                alignItems: "center",
                width: "100%", // Ensure full-width row
              }}
            >
              <PinComponent
                order={order}
                id={id}
                res={res}
                setRes={setRes}
                changeImportantorderstatus={changeImportantorderstatus}
                setChangeImportantorderstatus={setChangeImportantorderstatus}
              />
              <PinComponentMessage
                order={order}
                id={id}
                res={res}
                setRes={setRes}
              />

              <MarkOrderPaid row={row} />
            </Box>
          </Stack>
          <Box
            pl={3}
            pr={2}
            pt={3}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            className={classes.orderTopBar}
          >
            <Typography
              className={classes.orderIdBox}
              sx={{
                backgroundColor: "#000",
                color: "#fff",
                mr: "12px",
                p: "5px",
              }}
            >
              Order Id #{id}
            </Typography>
            <GoBackButton />
            {pagename === "pending-order" && (
              <OrderStatus
                row={row}
                changedorderstatus={changedorderstatus}
                setChangedOrderStatus={setChangedOrderStatus}
              />
            )}
            {pagename === "orders" && (
              <OrderStatus
                row={row}
                changedorderstatus={changedorderstatus}
                setChangedOrderStatus={setChangedOrderStatus}
              />
            )}
            {pagename === "print" && (
              <>
                <Box className={classes.orderStatusBtn}>
                  <OrderStatusForPrintJobs
                    row={row}
                    changedprintorderstatus={changedprintorderstatus}
                    setChangedPrintOrderStatus={setChangedPrintOrderStatus}
                  />
                </Box>
                <Button
                  className={classes.addOrderbtn}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ marginRight: "8px" }}
                  href="https://printyocrm.printyo.net.au/order/printdate/today"
                  target="_blank"
                >
                  Print Today Order(s)
                </Button>
                <Button
                  className={classes.addOrderbtn}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ marginRight: "8px" }}
                  onClick={() => setPrintOrderByDate(!printOrderByDate)}
                >
                  Print Order(s) by date
                </Button>
                <Button
                  className={classes.printOrderbtn}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleSelectedOrders}
                >
                  Print Order
                </Button>
              </>
            )}
            <Box
              className={classes.searchInputBox}
              sx={{
                position: "relative",
                marginLeft: "auto",
              }}
            >
              <TextField
                id="outlined-start-adornment"
                className={classes.searchInput}
                autoComplete="off"
                sx={{
                  m: 1,
                  width: "28ch",
                  backgroundColor: "#fff",
                  fontSize: "14px",
                }}
                size="small"
                placeholder="search"
                onChange={handleSearch}
                value={searchOrder}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "48px",
                  left: "8px",
                  width: "calc(100% - 16px)",
                  height: "auto",
                  background: "#fff",
                  zIndex: "999",
                  overflow: "auto",
                  borderRadius: "4px",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                {loading && <LinearProgress />}
                {searchOrderList && searchOrderList.length > 0 ? (
                  <List
                    sx={{
                      maxHeight: "300px",
                    }}
                  >
                    {searchOrderList.map(
                      (item, index) =>
                        index < 5 && (
                          <ListItem key={index}>
                            <ListItemText
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => handleOrderDetails(e, item.id)}
                            >
                              {`#${item.id}  ${item.billing.first_name} ${item.billing.last_name}`}
                            </ListItemText>
                          </ListItem>
                        )
                    )}
                  </List>
                ) : null}
              </Box>
            </Box>
            {/* Print-orders-by-date-component */}
          </Box>
          <Box pl={3} pr={3} className={classes.printOrderDateBox}>
            {printOrderByDate ? (
              <Box className={classes.orderbybox}>
                <Typography className={classes.orderbyheading}>
                  Print Order(s) by date
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  my={2}
                >
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="From"
                      required
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth size="small" />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="To"
                      required
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth size="small" />
                      )}
                    />
                  </LocalizationProvider>
                  <Button
                    className={classes.orderbyButton}
                    onClick={handleOrderByConfirmed}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Stack>
                <Typography style={{ color: "#d32f2f", fontSize: "12px" }}>
                  {fromError}
                </Typography>
                <Typography style={{ color: "#d32f2f", fontSize: "12px" }}>
                  {toError}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Container>
        <TabPanel className={classes.tabPanel} value={value} index={0}>
          <OrderDetails order={order} loading={loading} id={id} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={1}>
          <Activities order={order} id={id} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={2}>
          <Mockup order={order} id={id} print={print} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={3}>
          <Payment
            order={order}
            loading={loading}
            id={id}
            paidOrder={paidOrder}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={4}>
          <Notes order={order} id={id} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={5}>
          <PrintNotes order={order} id={id} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={6}>
          <Box px={3}>
            <Button
              className={classes.addOrderbtn}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                marginRight: "8px",
                backgroundColor: "#1976d2",
                color: "#fff",
              }}
              href="https://google.com/"
              target="_blank"
            >
              Button
            </Button>
          </Box>
        </TabPanel>
      </Box>
    </Layout>
  );
}
