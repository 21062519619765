import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Paper,
  Divider,
  Stack,
  Autocomplete,
  CircularProgress,
  Button,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DateAdapter from "@mui/lab/AdapterMoment";
import { styled } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Billing from "./components/Billing";
import Shipping from "./components/Shipping";
import {
  getCustomers,
  addOrderOnPrintyo,
  addCustomerOnPrintyo,
} from "../../api";
import PriceListContainer from "./components/PriceListContainer";
import Layout from "../../components/Layout";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";

const validationSchema = yup.object({});

// const initialValues = {
//     billing_first_name: '',
//     billing_last_name: '',
//     billing_country: '',
//     billing_address_1: '',
//     billing_address_2: '',
//     billing_city: '',
//     billing_company: '',
//     billing_email: '',
//     billing_phone: '',
//     billing_postcode: '',
//     billing_state: '',
//     shipping_first_name: '',
//     shipping_last_name: '',
//     shipping_country: '',
//     shipping_address_1: '',
//     shipping_address_2: '',
//     shipping_city: '',
//     shipping_company: '',
//     shipping_email: '',
//     shipping_phone: '',
//     shipping_postcode: '',
//     shipping_state: '',
// }
const TypographyHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const TypographySubHeading = styled(Typography)(({ theme }) => ({
  color: "#3c434a",
  fontSize: "13px",
  lineHeight: "1.4em",
  fontWeight: "bold",
}));
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "13px",
  lineHeight: "1.4em",
}));

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));

const AddOrder = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [toggleBilling, setToggelBilling] = useState(false);
  const [toggleShipping, setToggelShipping] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState([]);
  const [customer, setCustomer] = useState(null);
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [addToOrder, setaddToOrder] = useState(false);
  const [billingchecked, setBillingChecked] = useState(true);
  const [alertString, setAlertString] = useState("");
  const [alert, setAlert] = useState(false);
  const [responseSeverity, setResponseSeverity] = useState("");

  const [podcustomer, setPodCustomer] = useState(false);
  const [customerid, setCustomerId] = useState(0);
  // const [shippingPrice, setShippingPrice] = useState(0)
  // const [shippingMethod, setShippingMethod] = useState('Australian Post Standard')
  const [details, setDetails] = useState({
    payment_status: 10,
    order_status: 10,
    new_customer: "",
    customer_note: "",
  });
  const [shippingInfo, setShippingInfo] = useState({
    shippingMethod: "printyo_shipping_method",
    shippingPrice: "0",
    shippingName: "Free pick-up from store",
  });

  const loading = open && options.length === 0;

  const auth_email = localStorage.getItem("auth_email");
  const auth_name = localStorage.getItem("auth_name");
  const auth_id = localStorage.getItem("auth_id");

  // useEffect(() => {
  //   const fetchCustomer = async () => {
  //     if (value.length >= 2) {
  //       let customers = await getCustomers(value);
  //       setOptions([...customers]);
  //       setBillingChecked(false);
  //     }
  //   };
  //   fetchCustomer();
  // }, [value]);

  const handlePodCustomer = (event) => {
    setPodCustomer(event.target.checked);
  };
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const fetchCustomer = async () => {
        if (value.length >= 2) {
          let customers = await getCustomers(value);
          setOptions([...customers]);
          setBillingChecked(false);
        }
      };
      fetchCustomer();
    }, 500); // Set the debounce time in milliseconds (adjust as needed)

    return () => clearTimeout(debounceTimer); // Clear the timeout on component unmount or value change
  }, [value]);

  useEffect(() => {
    const fetchCustomer = async () => {
      let customers = await getCustomers();
      setOptions([...customers]);
      setBillingChecked(false);
    };
    fetchCustomer();
  }, []);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleSubmit = async (values, setSubmitting) => {
    setaddToOrder(true);
    const line_items = [];
    let customaddedproduct = null;
    let ordermetas = [
      {
        key: "pcrm_order_status",
        value: details.order_status === 10 ? "processing" : "on-hold",
      },
      {
        key: "pcrm_payment_status",
        value: details.payment_status === 10 ? "paid" : "unpaid",
      },
      {
        key: "pcrm_order_handling_userid",
        value: auth_id,
      },
      {
        key: "pcrm_order_handling_username",
        value: auth_name,
      },
      {
        key: "pcrm_order_handling_useremail",
        value: auth_email,
      },
      {
        key: "pcrm_order_created_through",
        value: "arte",
      },
    ];
    const customproduct = await products.forEach((item) => {
      const meta_data = [];
      for (const metas in item.attributes) {
        if ("custom-product-order" in item.attributes) {
          customaddedproduct = true;
        }
        meta_data.push({
          key: metas,
          value: item.attributes[metas],
        });
      }
      line_items.push({
        product_id: item.product.id ? item.product.id : 1,
        quantity: item.qty,
        //variation_id: item.product.variations,
        name: item.product.name,
        // parent_name: item.product.name,
        // price: item.price,
        // sku: item.product.sku,
        // subtotal: item.total,
        total: item.total + "",
        meta_data,
      });
    });
    var shipping = {};
    if (customaddedproduct) {
      ordermetas.push({
        key: "pcrm_order_custom_product",
        value: "yes",
      });
    }
    if (billingchecked || !values.shipping_first_name) {
      shipping.first_name = values.billing_first_name;
      shipping.last_name = values.billing_last_name;
      shipping.address_1 = values.billing_address_1;
      shipping.address_2 = values.billing_address_2;
      shipping.city = values.billing_city;
      shipping.state = values.billing_state;
      shipping.postcode = values.billing_postcode;
      shipping.country = values.billing_country;
    } else {
      shipping.first_name = values.shipping_first_name;
      shipping.last_name = values.shipping_last_name;
      shipping.address_1 = values.shipping_address_1;
      shipping.address_2 = values.shipping_address_2;
      shipping.city = values.shipping_city;
      shipping.state = values.shipping_state;
      shipping.postcode = values.shipping_postcode;
      shipping.country = values.shipping_country;
    }
    const data = {
      billing: {
        first_name: values.billing_first_name,
        last_name: values.billing_last_name,
        address_1: values.billing_address_1,
        address_2: values.billing_address_2,
        city: values.billing_city,
        state: values.billing_state,
        postcode: values.billing_postcode,
        country: values.billing_country,
        email: values.billing_email,
        phone: values.billing_phone,
      },
      shipping,
      line_items,
      customer_id: customerid,
      shipping_lines: [
        {
          method_id: shippingInfo.shippingMethod,
          method_title: shippingInfo.shippingName,
          total: shippingInfo.shippingPrice + "",
        },
      ],
      meta_data: ordermetas,
      set_paid: details.payment_status === 10 ? false : true,
      status: details.payment_status === 20 ? "processing" : "pending",
    };
    try {
      const pushdata = await addOrderOnPrintyo(data);
      if (pushdata.id) {
        setAlert(true);
        setAlertString("Order Created Successfully");
        setResponseSeverity("success");
        setPodCustomer(false);
      } else {
        setAlert(true);
        setAlertString(
          pushdata.data.params.status
            ? pushdata.data.params.status
            : pushdata.message
        );
        setResponseSeverity("error");
      }
      // if (pushdata.data.status == 400 || pushdata.data.status == 500) {
      //   setAlert(true);
      //   setAlertString(pushdata.data.params.status);
      //   setResponseSeverity("error");
      // } else {
      //   setAlert(true);
      //   setAlertString("Order Created Successfully");
      //   setResponseSeverity("success");
      // }
    } catch (e) {
      console.log(e);
      setAlert(true);
      setAlertString(
        "Something went wrong while adding order, please check all your fields properly"
      );
      setResponseSeverity("error");
    }
    setaddToOrder(false);
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleBillingChange = (setFieldValue) => {
    setBillingChecked(!billingchecked);
  };
  //  const handleWooOrderCreate = () => {};

  const [addToCustomer, setAddToCustomer] = useState(false);
  const ref = useRef(null);
  const handleAddCustomer = async (values) => {
    setAddToCustomer(true);
    var email = ref.current.values.billing_email;
    var first_name = ref.current.values.billing_first_name;
    var last_name = ref.current.values.billing_last_name;
    var username = details.new_customer;
    var shipping = {};
    var billing = {};
    var metadata = [];
    if (podcustomer) {
      metadata.push({
        key: "pod_customer",
        value: "yes",
      });
    }
    if (billingchecked || !ref.current.values.shipping_first_name) {
      shipping.first_name = ref.current.values.billing_first_name;
      shipping.last_name = ref.current.values.billing_last_name;
      shipping.address_1 = ref.current.values.billing_address_1;
      shipping.address_2 = ref.current.values.billing_address_2;
      shipping.city = ref.current.values.billing_city;
      shipping.state = ref.current.values.billing_state;
      shipping.postcode = ref.current.values.billing_postcode;
      shipping.country = ref.current.values.billing_country;
    } else {
      shipping.first_name = ref.current.values.shipping_first_name;
      shipping.last_name = ref.current.values.shipping_last_name;
      shipping.address_1 = ref.current.values.shipping_address_1;
      shipping.address_2 = ref.current.values.shipping_address_2;
      shipping.city = ref.current.values.shipping_city;
      shipping.state = ref.current.values.shipping_state;
      shipping.postcode = ref.current.values.shipping_postcode;
      shipping.country = ref.current.values.shipping_country;
    }
    billing = {
      first_name: ref.current.values.billing_first_name,
      last_name: ref.current.values.billing_last_name,
      address_1: ref.current.values.billing_address_1,
      address_2: ref.current.values.billing_address_2,
      city: ref.current.values.billing_city,
      state: ref.current.values.billing_state,
      postcode: ref.current.values.billing_postcode,
      country: ref.current.values.billing_country,
      email: ref.current.values.billing_email,
      phone: ref.current.values.billing_phone,
    };

    // Remove properties with null or empty string values
    const filteredBilling = Object.fromEntries(
      Object.entries(billing).filter(
        ([key, value]) => value && value.trim() !== ""
      )
    );

    // Remove properties with null or empty string values
    const filteredShipping = Object.fromEntries(
      Object.entries(shipping).filter(
        ([key, value]) => value && value.trim() !== ""
      )
    );

    const data = {
      data: {
        ...(Object.keys(filteredBilling).length > 0 && {
          billing: filteredBilling,
        }),
        ...(Object.keys(filteredShipping).length > 0 && {
          shipping: filteredShipping,
        }),
        email,
        first_name,
        last_name,
        username,
        meta_data: metadata,
      },
    };
    try {
      const response = await addCustomerOnPrintyo(data);
      const returnresponse = response.response;
      console.log(response.response);
      if ((returnresponse.status = 400)) {
        setAlert(true);
        setAlertString(returnresponse.message);
        setResponseSeverity("error");
      }
      if (returnresponse.id) {
        setAlert(true);
        setAlertString("Customer Created Successfully");
        setResponseSeverity("success");
        setPodCustomer(false);
        setCustomerId(response.id);
      }
      console.log(data);
    } catch (error) {
      //console.log(error)
      console.log(error);
      setAlert(true);
      setAlertString("Something went wrong while adding customer", error);
      setResponseSeverity("error");
    }
    setAddToCustomer(false);
  };

  return (
    <Layout>
      <Box Box pt={4} bgcolor="#f0f0f1" minHeight={`calc(100vh - 72px)`}>
        <Container sx={{ bgcolor: "#f0f0f1" }}>
          {alert ? (
            <Alert
              severity={responseSeverity}
              sx={{
                margin: "10px 0 20px",
              }}
            >
              {alertString}
            </Alert>
          ) : null}
          <Formik
            innerRef={ref}
            enableReinitialize
            const
            initialValues={{
              billing_first_name: customer ? customer.billing.first_name : "",
              billing_last_name: customer ? customer.billing.last_name : "",
              billing_country: customer ? customer.billing.country : "",
              billing_address_1: customer ? customer.billing.address_1 : "",
              billing_address_2: customer ? customer.billing.address_2 : "",
              billing_city: customer ? customer.billing.city : "",
              billing_company: customer ? customer.billing.company : "",
              billing_email: customer ? customer.billing.email : "",
              billing_phone: customer ? customer.billing.phone : "",
              billing_postcode: customer ? customer.billing.postcode : "",
              billing_state: customer ? customer.billing.state : "",
              shipping_first_name: customer ? customer.shipping.first_name : "",
              shipping_last_name: customer ? customer.shipping.last_name : "",
              shipping_country: customer ? customer.shipping.country : "",
              shipping_address_1: customer ? customer.shipping.address_1 : "",
              shipping_address_2: customer ? customer.shipping.address_2 : "",
              shipping_city: customer ? customer.shipping.city : "",
              shipping_company: customer ? customer.shipping.company : "",
              shipping_email: customer ? customer.shipping.email : "",
              shipping_phone: customer ? customer.shipping.phone : "",
              shipping_postcode: customer ? customer.shipping.postcode : "",
              shipping_state: customer ? customer.shipping.state : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
          >
            {({ isSubmitting, errors, touched, values, setFieldValue }) => (
              <Form>
                <Paper
                  sx={{
                    p: 2,
                    "& .MuiFormControl-root": {
                      "& label": {
                        fontSize: "14px",
                      },
                      "& input": {
                        fontSize: "14px",
                        color: "#6B7280",
                      },
                    },
                  }}
                >
                  <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    mb={1}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "1.5em",
                      }}
                    >
                      Add Order
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      type="submit"
                      disabled={addToOrder}
                    >
                      {addToOrder ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        " Create order"
                      )}
                    </Button>
                  </Stack>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12} sx={{ py: 2 }}>
                      <TypographyHeading sx={{ mt: 1, mb: 3 }}>
                        General
                      </TypographyHeading>
                      <Stack spacing={3} mb>
                        <FormControl>
                          <InputLabel id="order_status">
                            Order Status
                          </InputLabel>
                          <Select
                            className={classes.fullWidth}
                            labelId="demo-simple-select-helper-label"
                            id="order_status"
                            value={details.order_status}
                            label="Payment status"
                            onChange={handleChange}
                            size="small"
                            name="order_status"
                            sx={{ fontSize: "14px", width: "70%" }}
                          >
                            <MenuItem value={10}>Process</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl>
                          <InputLabel id="payment_status">
                            Payment status
                          </InputLabel>
                          <Select
                            className={classes.fullWidth}
                            labelId="demo-simple-select-helper-label"
                            id="payment_status"
                            value={details.payment_status}
                            label="Payment status"
                            onChange={handleChange}
                            size="small"
                            name="payment_status"
                            sx={{ fontSize: "14px", width: "70%" }}
                          >
                            <MenuItem value={10}>Paid</MenuItem>
                            <MenuItem value={20}>UnPaid</MenuItem>
                          </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <DateTimePicker
                            label="Date&Time picker"
                            value={date}
                            onChange={(newValue) => {
                              setDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                className={classes.fullWidth}
                                {...params}
                                size="small"
                                sx={{ width: "70%" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <Autocomplete
                          id="asynchronous-demo"
                          className={classes.fullWidth}
                          sx={{ width: "70%" }}
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          size="small"
                          // isOptionEqualToValue={(option, value) =>
                          //   option.first_name === value.first_name
                          // }
                          getOptionLabel={(option) => {
                            return option.first_name + option.last_name;
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              sx={{ fontSize: "12px" }}
                            >
                              {option.first_name}({option.email})
                            </Box>
                          )}
                          filterOptions={(x) => x}
                          onInputChange={(event, newInputValue) => {
                            setValue(newInputValue);
                          }}
                          onChange={(e, v) => {
                            setOptions(v ? [v, ...options] : options);
                            setCustomer(v);
                            setToggelShipping(true);
                            setToggelBilling(true);
                            setCustomerId(v ? v.id : 0);
                          }}
                          options={options}
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                        <Field
                          className={classes.fullWidth}
                          name="new_customer"
                          size="small"
                          as={TextField}
                          value={details.new_customer}
                          variant="outlined"
                          placeholder="Add new Customer Username"
                          label="Add new Customer Username"
                          sx={{ width: "70%" }}
                          onChange={handleChange}
                        />
                        <FormGroup>
                          <FormControlLabel
                            //onChange={handlePodCustomer}
                            control={
                              <Checkbox
                                checked={podcustomer}
                                onChange={handlePodCustomer}
                              />
                            }
                            label="Pod Customer"
                          />
                        </FormGroup>
                      </Stack>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} sx={{ py: 2 }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <TypographyHeading>Billing</TypographyHeading>
                        <IconButton
                          onClick={() => setToggelBilling(!toggleBilling)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      {toggleBilling ? (
                        <Billing
                          errors={errors}
                          customer={customer}
                          //onChange={this.onChange.bind(setFieldValue)}
                          setFieldValue={setFieldValue}
                        />
                      ) : (
                        <>
                          <TypographySubHeading>Address:</TypographySubHeading>
                          <CustomTypography>
                            No billing address set.
                          </CustomTypography>
                          <TypographySubHeading>
                            Email address:
                          </TypographySubHeading>
                        </>
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={billingchecked}
                            onChange={handleBillingChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Shipping same as billing"
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} sx={{ py: 2 }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <TypographyHeading>Shipping</TypographyHeading>
                        <IconButton
                          onClick={() => setToggelShipping(!toggleShipping)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      {toggleShipping ? (
                        <Shipping
                          errors={errors}
                          customer={customer}
                          details={details}
                          setFieldValue={setFieldValue}
                        />
                      ) : (
                        <>
                          <TypographySubHeading>Shipping:</TypographySubHeading>
                          <CustomTypography>
                            No shipping address set.
                          </CustomTypography>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ py: 2 }}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        variant="contained"
                        sx={{ textTransform: "none" }}
                        type="button"
                        onClick={handleAddCustomer}
                        disabled={addToCustomer}
                      >
                        {addToCustomer ? "Adding..." : "Add Customer"}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <PriceListContainer
                  shippingDetails={
                    values.shipping_postcode
                      ? values.shipping_postcode
                      : values.billing_postcode
                      ? values.billing_postcode
                      : ""
                  }
                  products={products}
                  setProducts={setProducts}
                  totalPrice={totalPrice}
                  setTotalPrice={setTotalPrice}
                  setShippingInfo={setShippingInfo}
                  shippingInfo={shippingInfo}
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Layout>
  );
};

export default AddOrder;
